import React, { useState } from 'react';
import { Heading } from "@chakra-ui/core";
import { Flex } from "@chakra-ui/react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '../components/layout';
import Poloroid from '../components/template-parts/poloroid';
import PhoneInput from 'react-phone-number-input/input'
import  gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

const CONTACT_MUTATION = gql`
  mutation CreateSubmissionMutation($clientMutationId: String!, $name: String!, $email: String!, $phone: String!, $ruid: String!, $gradYear: String!, $dateSubmitted: String!){
    createSubmission(input: {clientMutationId: $clientMutationId, email: $email, gradYear: $gradYear, name: $name, phone: $phone, ruid: $ruid, dateSubmitted: $dateSubmitted}) {
      data
      success
    }
  }
`;

const Contact = (props => {

   const data = useStaticQuery(graphql`
    query {
      wpPage(title: {eq: "Contact Data"}) {
        contactData {
          contactInformation {
            contactEmail
            contactPhone
            contactName
            contactPosition
            contactImage {
              id
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          banner {
            id
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const [createSubmission] = useMutation(CONTACT_MUTATION, {
    onError(err){
      renderErrors(err);
    }
  });

  const{ contactData: { 
    banner, contactInformation
  } } = data.wpPage;
  

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState(undefined);
  const [ruid, setRuid] = useState(undefined);
  const [YOG, setYOG] = useState();
  const [inputKey, setInputKey] = useState("");

  const [formErrors, setFormErrors] = useState();
  const [success, setSuccess] = useState(false);
  
  // for the year of graduation, make a dropdown, show years form current to 5 years form now.

  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if(inputKey !== "") {
      return setFormErrors(
        <span className="contact-error">There was an Error</span>
      )
    } 

    if(name === "" || email === "" || !phone || !ruid || !YOG){
      return setFormErrors(
        <span className="contact-error">Please Submit All Fields</span>
      )
    }

    const clientMutationId = String(uuidv4());
    const dateSubmitted = String(Math.floor(Date.now() / 1000));
    // store other fields that may come in handy.
    // ie. Month, and day, and weekday
      
    return createSubmission({
      variables: {
        clientMutationId,
        name,
        email,
        phone,
        ruid,
        gradYear: YOG,
        dateSubmitted
      }
    }).then(res => {
      console.log("success!");

      setSuccess(true);
    })
  }

  const renderErrors = errors => {
    console.log(errors);
  }
  
  return (
    <Layout>
    <section className="page-container" >
      <div className="inner-banner stn-banner">
        <Img 
          fluid={banner.localFile.childImageSharp.fluid}
          alt={`banner-img-${banner.id}`}
        />
      </div>
      <div className="contact-content">
        <Heading as="h1">Contact Us</Heading>

        <Flex 
          className=""
          flexDir={["column", "row"]}
        >
          
          <div className="col-1-3">
            <Poloroid 
              image={contactInformation.contactImage.localFile.childImageSharp.fluid}
              dark={true}
              name={contactInformation.contactName}
              small={true}
              position={contactInformation.contactPosition}
            />

            <Flex 
              className="quick-contact"
              flexDir="column"
            >
              <p>P: <a 
              href={`tel:${contactInformation.contactPhone}`}>
                {contactInformation.contactPhone}
              </a></p>
              <p>E: <a 
              href={`mailto:${contactInformation.contactEmail}`}>
                {contactInformation.contactEmail}
              </a></p>
            </Flex>
          </div>

          <div className="col-2-3">

            {success ? (
              <div>
                <h3>Thank You for contacting us. We will be in contact shortly!</h3>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                
                <Flex 
                  className="row-1"
                  flexDir={["column", "row"]}
                >
                  <input 
                    type="text"
                    placeholder="Name"
                    value={name || ""}
                    onChange={e => setName(e.target.value)}
                  />
                  <input 
                    type="email"
                    placeholder="Email"
                    value={email || ""}
                    onChange={e => setEmail(e.target.value)}
                  />
                </Flex>
  
                <Flex 
                  className="row-2"
                  flexDir={["column", "row"]}
                >
                  <PhoneInput 
                    country="US"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={setPhone}
                  />
                  <input 
                  type="number" 
                  pattern="[0-9]*" 
                  inputMode="numeric"
                  value={ruid || ""}
                  placeholder="RUID"
                  max="999999999"
                  onChange={e => setRuid(e.target.value)}
                  />
                </Flex>
  
                <Flex 
                  className="row-3"
                  flexDir={["column", "row"]}
                >
                  <input 
                    type="number"
                    placeholder="Graduating Year"
                    value={YOG || ""}
                    onChange={e => setYOG(e.target.value)}
                  />
                  <label 
                    htmlFor="contact-submit"
                    className="link-btn"
                  >
                    Submit
                    <FontAwesomeIcon icon={['fas','angle-right']}/>
                    <FontAwesomeIcon icon={['fas','angle-right']}/>
                    <FontAwesomeIcon icon={['fas','angle-right']}/>
                  </label>
                  
                  <input type="submit" id="contact-submit"/>
                </Flex>
                <input 
                  type="text"
                  placeholder="Address"
                  value={inputKey || ""}
                  onChange={e => setInputKey(e.target.value)}
                />
                <p className="form-disc">By hitting Submit, you agree to be subscribing to our mailing list(terms and conditions)</p>
                {formErrors}
              </form>
            )}

          </div>

        </Flex>
      </div>
    </section>
    </Layout>
  )
});

export default Contact;